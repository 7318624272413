<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>Post Analysis Videos</h2>
    <div class="uk-alert uk-alert-info" v-if="videos.length == 0">
      There is no data to display
    </div>
    <div class="uk-grid">
      <div
        class="uk-width-1-4@m"
        v-for="(video, i) in videos"
        :key="`video-${i}`"
      >
        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-media-top">
            <router-link :to="{ name: 'trader.post-analysis.show', params: { id: video.id }}"><img :src="video.thumbnail" :alt="video.title" /></router-link>
          </div>
          <div class="uk-card-body">
            <router-link :to="{ name: 'trader.post-analysis.show', params: { id: video.id }}"><h3 class="uk-card-title uk-margin-remove">{{ video.title }}</h3></router-link>
            <p class="uk-margin-remove">
              By <router-link :to="{ name: 'trader.users.show', params: { id: video.user_id }}">{{ video.user }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      videos: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios.get("/api/v1/trader/post-analysis").then((response) => {
        this.videos = response.data.videos;
        this.$loader.stop();
      });
    },
  },
};
</script>